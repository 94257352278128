// JS SPECIFIC TO DEPOSIT IDEA PAGE
(function ($) {


    $(function () {

        // Fix ios8 && fastclick conflict : add class needsclick on dropzone clickable element
        setTimeout(function(){
            $('.dropzone_widget').find(".dz-clickable").addClass('needsclick');
        },200);

        var getAllCheckbox = function (checkboxes) {
            var canSend = true

            $(checkboxes).each(function (i, el) {
                var _this = $(this);
                if (!_this.is(':checked')) {
                    canSend = false
                }
            })

            return canSend
        }

        var
            form = $('form.deposit-idea-form'),
            required_checkbox = form.find('input[type="checkbox"][required]');

        // When page is loaded, check id checkboxes are already checked
        if (getAllCheckbox(required_checkbox)) {
            form.find('button[type="submit"]').removeClass('cc-disabled').prop('disabled', false)
        } else {
            form.find('button[type="submit"]').addClass('cc-disabled').prop('disabled', true)
        }

        // Do the same thing when a checkbox changes
        $(required_checkbox).on('change', function (e) {
            if (getAllCheckbox(required_checkbox)) {
                form.find('button[type="submit"]').removeClass('cc-disabled').prop('disabled', false)
            } else {
                form.find('button[type="submit"]').addClass('cc-disabled').prop('disabled', true)
            }
        });

        $('.thematic-item:not(.astuce-push)').on('click', function (e) {
            e.preventDefault();
            $('.thematic-item').removeClass("selected");
            $(this).addClass("selected");
            $('section.main').addClass("form-initiated");
            $('#project_submission_theme').val($(this).attr('data-id'));


            // Scroll to the form when thematic is picked
            var _target = $('.wrap-form');
            var _top = parseInt(_target.offset().top - 120);
            $('html, body').stop(true, true).animate({scrollTop: _top}, 500, function() {});

        });

        if ($('section.form-initiated').length > 0) {
            $('.wrap-thematics div[data-id="' + $('#project_submission_theme').val() + '"]').addClass("selected");
        }

    });
})(jQuery);

