var cookies = [
    {
        'name': 'gtm',
        'consent': false,
        'callback': function () {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'gtm.start': new Date().getTime(),
                event: 'gtm.js'
            });
            addScript("https://www.googletagmanager.com/gtag/js?id=UA-64548448-2");
        }
    },
    {
        'name': 'fb',
        'consent': false,
        'callback': function () {}
    },
    {
        'name': 'matomo',
        'consent': false,
        'callback': function () {
            var _paq = window._paq = window._paq || [];
            _paq.push(['trackPageView']);
            _paq.push(['enableLinkTracking']);
            var u="https://tivoly.matomo.cloud/";
            _paq.push(['setTrackerUrl', u+'matomo.php']);
            _paq.push(['setSiteId', '3']);
            addScript("https://cdn.matomo.cloud/tivoly.matomo.cloud/matomo.js");
        }
    }
];

const cookieKey = "_consent";

$(function () {

    // Init : Either show modal or set current cookies in document
    cookieInit();

    // Open cookies modal
    $(document).on('click', '[data-open-cookies]', function(e){
        openCookieBox();
    });

    // Close cookies modal
    $(document).on('click', '[data-close-cookies]', function(e){
        closeCookieBox();
    });

    // Accept all cookies
    $(document).on('click', 'a[data-accept-all]', function(e){
        e.preventDefault();
        allowAll();
        saveCookie();
        changeState($('.btn-cookie-accept'), true);
        changeState($('.btn-cookie-deny'), false);
        closeCookieBox();
    });

    // Deny all choices
    $(document).on('click', '[data-deny-all]', function(e){
        disallowAll();
        saveCookie();
        changeState($('.btn-cookie-accept'), false);
        changeState($('.btn-cookie-deny'), true);
        closeCookieBox();
    });


    // Show choices modal
    $(document).on('click', 'a[custom-cookie]', function(){
        $('#cookie-details').show();
        $('#cookie-all').hide();
    })

    // Show first modal
    $(document).on('click', 'a[back-cookie]', function(){
        $('#cookie-details').hide();
        $('#cookie-all').show();
    })

    // Autoriser ce cookie
    $(document).on('click', '.btn-cookie-accept', function(e){
        allow($(this).attr('data-consent'));
        saveCookie();
        $(this).addClass('active').parent().find('.btn-cookie-deny').removeClass('active');
    });


    // Interdire ce cookie
    $(document).on('click', '.btn-cookie-deny', function(e){
        disallow($(this).attr('data-consent'));
        saveCookie();
        $(this).addClass('active').parent().find('.btn-cookie-accept').removeClass('active');
    });

    // Save my choices
    $(document).on('click', '[data-cookies-save]', function(e){
        $('.btn-cookie-choice.active').trigger('click');
        closeCookieBox();
    });
});

function cookieInit() {

    if (getCookie(cookieKey) === null) {

        openCookieBox();

    } else {

        for (var y = 0; y < cookies.length; y++) {
            cookies[y].consent = getCookie(cookieKey + '_' + cookies[y].name);

            if($('[data-consent="' + cookies[y].name + '"]').length > 0) {

                switch(cookies[y].consent) {
                    case 'false' :
                        $('[data-consent="' + cookies[y].name + '"].btn-cookie-deny')
                            .addClass('active')
                            .parent()
                            .find('.btn-cookie-accept')
                            .removeClass('active');
                        break;
                    case 'true' :
                        $('[data-consent="' + cookies[y].name + '"].btn-cookie-accept')
                            .addClass('active')
                            .parent()
                            .find('.btn-cookie-deny')
                            .removeClass('active');
                        break;
                }
            }
        }
    }
}

function openCookieBox() {
    $('#cookies').addClass('shown');
}

function closeCookieBox() {
    $('#cookies').removeClass('shown');
}

function saveCookie() {
    var d = new Date();
    d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toGMTString();

    document.cookie = cookieKey + "=true; " + expires + ";path=/";
    for (var i = 0; i < cookies.length; i++) {
        document.cookie = cookieKey + '_' + cookies[i].name + "=" + cookies[i].consent + "; " + expires + ";path=/";
    }
}

function getCookie(c_name) {
    var c_value = " " + document.cookie;
    var c_start = c_value.indexOf(" " + c_name + "=");
    if (c_start === -1) {
        c_value = null;
    } else {
        c_start = c_value.indexOf("=", c_start) + 1;
        var c_end = c_value.indexOf(";", c_start);
        if (c_end === -1) {
            c_end = c_value.length;
        }
        c_value = unescape(c_value.substring(c_start, c_end));
    }
    return c_value;
}

function allow(consentKey) {
    for (var i = 0; i < cookies.length; i++) {
        if (cookies[i].name === consentKey) {
            cookies[i].consent = true;
            cookies[i].callback();
        }
    }
}

function allowAll() {
    for (var i = 0; i < cookies.length; i++) {
        allow(cookies[i].name);
    }
}

function disallow(consentKey) {
    for (var i = 0; i < cookies.length; i++) {
        if (cookies[i].name === consentKey) {
            cookies[i].consent = false;
        }
    }
}

function disallowAll() {
    for (var i = 0; i < cookies.length; i++) {
        disallow(cookies[i].name);
    }
}

function addScript(url) {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
}

function changeState(element, state) {
    if(state) {
        element.addClass('active')
    } else {
        element.removeClass('active')
    }
}
