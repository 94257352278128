// JS SPECIFIC TO PROFILE DETAIL PAGE
(function($) {

    function createMap(elementId, lat, long, markerUrl) {
        var map = L.map(elementId, {
            center: [lat,long],
            zoom: 12,
            layers: [
                L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png')
            ],
        });

        map.zoomControl.setPosition('bottomright');
        map.scrollWheelZoom.disable();

        // Creating a marker
        var LeafIcon = L.Icon.extend({
            options: {
                iconSize:     [32,32],
                iconAnchor:   [16,16]
            }
        });
        var customMarker = new LeafIcon({
            iconUrl: markerUrl
        })
        L.marker([lat, long], {icon: customMarker}).addTo(map);
    }

    $(function () {

        /*
            -- @ GOOGLE MAPS @ --
        */
        if($('#maps-contact').length) {
            createMap('maps-contact', 45.663261, 6.430005, $('#maps-contact').data('marker'));
        }
    });
})(jQuery);
