// JS SPECIFIC TO USER ACCOUNT PAGES
(function ($) {

    function isEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }


    $(function () {

        // Fix ios8 && fastclick conflict : add class needsclick on dropzone clickable element
        setTimeout(function(){
            $('.registration-form').find(".dz-clickable").addClass('needsclick');
        },200);

        onHandyManLevelChange();

        $('#user_form_handymanLevel').on('change', function (e) {
            onHandyManLevelChange();
        });

        if ($('#user_complete_profile_form_isProfessional_0, #user_form_isProfessional_0').attr('checked')) {
            $('.is-pro').removeClass('cc-hidden');
            $('.is-not-pro').addClass('cc-hidden');
        }

        if ($('#user_form_isProfessional_1').attr('checked')) {
            $('.is-pro').addClass('cc-hidden');
            $('.is-not-pro').removeClass('cc-hidden');
        }

        $('#user_complete_profile_form_isProfessional_0, #user_form_isProfessional_0').on('change', function () {
            $('.is-pro').removeClass('cc-hidden');
            $('.is-not-pro').addClass('cc-hidden');
        });

        $('#user_complete_profile_form_isProfessional_1, #user_form_isProfessional_1').on('change', function () {
            $('.is-pro').addClass('cc-hidden');
            $('.is-not-pro').removeClass('cc-hidden');
        });

        // MY PROFIL - SHOW/HIDE PRO FIELDS
        if ($('.js-switch-pro').length) {

            // Show or Hide PRO fields and add required attribute for required fields
            var showHideFields = function (val, fields) {

                // If user is a professional
                if (val == 1) {
                    fields.addClass('shown');
                    fields.find('input.js-required').attr('required', 'required');
                    fields.find('input.js-required').attr('required', true);
                } else {
                    fields.removeClass('shown');
                    fields.find('input.js-required').removeAttr('required');
                }
            }

            var
                input = $('.js-switch-pro').find('input:checked'),
                fields = $('.js-show-pro');

            // Show or Hide Pro fields
            showHideFields(input.val(), fields)
        }


        // When Pro/Indiv changes
        $(document).on('change', '.js-switch-pro input', function (e) {
            var
                input = $('.js-switch-pro').find('input:checked'),
                fields = $('.js-show-pro');

            // Show or Hide Pro fields
            showHideFields(input.val(), fields)
        })


        // User MENU in accounts pages
        $(document).on('click', '.open-user-menu a', function (e) {
            e.preventDefault();

            $('html, body').addClass('opened-menu');
            $('#content aside nav').addClass('shown');
        })
        $(document).on('click', '.close-user-menu a', function (e) {
            e.preventDefault();
            $('html, body').removeClass('opened-menu');
            $('#content aside nav').removeClass('shown');
        });


        if(document.querySelector('.user-account aside nav') !== null) {
            var hammertime = new Hammer(document.querySelector('.user-account aside nav'));
            hammertime.on('swipeleft', function (ev) {
                $('html, body').removeClass('opened-menu');
                $('#content aside nav').removeClass('shown');
            });
        }



        // Form CHANGE PASSWORD in Profil account page
        var checkPasswd = function (new_pass, confirm_pass) {
            return !($.trim(new_pass.val()) === '' || ($.trim(new_pass.val()) !== $.trim(confirm_pass.val())));
        }

        // Form into my profil page : edit my password
        $(document).on('submit', 'form.js-change-passwd', function (e) {
            var
                form = $(this),
                new_pass = form.find('input[name="fos_user_change_password_form[plainPassword][first]"]'),
                confirm_pass = form.find('input[name="fos_user_change_password_form[plainPassword][second]"]');

            if (!checkPasswd(new_pass, confirm_pass)) {
                e.preventDefault();
                $('.js-no-equals').show();
            }
        })

        // Form into resetting passwd page
        $(document).on('submit', 'form.fos_user_resetting_reset', function (e) {
            var
                form = $(this),
                new_pass = form.find('input[name="fos_user_resetting_form[plainPassword][first]"]'),
                confirm_pass = form.find('input[name="fos_user_resetting_form[plainPassword][second]"]');

            if (!checkPasswd(new_pass, confirm_pass)) {
                e.preventDefault();
                $('.js-no-equals').show();
            }
        })

        // Form into registration page
        $(document).on('submit', 'form.registration-form', function (e) {
            var
                form = $(this),
                new_pass = form.find('input[name="user_registration_form[plainPassword][Mot_de_passe]"]'),
                confirm_pass = form.find('input[name="user_registration_form[plainPassword][Confirmation_du_mot_de_passe]"]');

            if (new_pass.length > 0 && confirm_pass.length > 0 && !checkPasswd(new_pass, confirm_pass)) {
                e.preventDefault();
                $('.js-no-equals').show();

                form.find('fieldset').removeClass('shown')
                form.find('fieldset.item-1').addClass('shown').find('#user_form_plainPassword .form-item').addClass('error')
                showSteps(0, 1) // <-- show first step

            }
        })


        // REGISTRATION FORM : Switch Fieldsets
        var showSteps = function (stepFrom, stepToGo) {
            switch (stepToGo) {
                case 0:
                    $('.prev-step').addClass('cc-hidden')
                    $('.next-step').removeClass('cc-hidden')
                    break;
                case 1:
                    $('.prev-step, .next-step').removeClass('cc-hidden')
                    break;
                case 2:
                    $('.prev-step, .next-step').removeClass('cc-hidden')
                    break;
                case 3:
                    $('.prev-step').removeClass('cc-hidden')
                    $('.next-step').addClass('cc-hidden')
                    break;
                default:
                    $('.prev-step').addClass('cc-hidden')
                    $('.next-step').removeClass('cc-hidden')
            }
        }
        // NEXT STEP
        $(document).on('click', '.next-step a', function (e) {
            e.preventDefault();

            var
                nbFieldset = $('form.registration-form fieldset').length,

                // Get the current visible fieldset
                currentF = $('form.registration-form fieldset.shown'),

                // Get the index of the current visible fieldset
                index = parseInt($('form.registration-form fieldset').index(currentF)),

                // Set canSwitch to true by default
                canSwitch = true;


            // Check if required inputs into the current fieldset are filled
            currentF.find('*[required]').each(function (i, el) {
                var input = $(this)

                if ($.trim(input.val()) === '' || (input.attr('type') === 'email' && !isEmail(input.val()))) {
                    input.parents('.form-item').addClass('error')
                    canSwitch = false
                } else {
                    input.parents('.form-item').removeClass('error')
                }
            })


            if (canSwitch) {

                // Show/hide prev/next buttons
                showSteps(index, (index + 1))

                // Show next fieldset
                currentF.removeClass('shown').next().addClass('shown').find('input[type="text"]:first').focus()

                // Scroll to first input
                $.scrollTo($('form.registration-form'), 350, {offset: -250, axis: 'y'});

            } else {

                // Si on ne peut pas switcher de fieldset, on scroll jusqu'à la première erreur
                $.scrollTo(currentF.find('.form-item.error:first'), 350, {offset: -100, axis: 'y'});
            }
        })

        // PREV STEP
        $(document).on('click', '.prev-step a', function (e) {
            e.preventDefault();

            var
                // Get the current visible fieldset
                currentF = $('form.registration-form fieldset.shown'),

                // Get the index of the current visible fieldset
                index = parseInt($('form.registration-form fieldset').index(currentF));

            // Show/hide prev/next buttons
            showSteps(index, (index - 1))

            // Show previous fieldset
            currentF.removeClass('shown').prev().addClass('shown').find('input[type="text"]:first').focus()

            // Scroll to first input
            $.scrollTo($('form.registration-form'), 350, {offset: -250, axis: 'y'});
        })

        // IF PRESS "ENTER" TOUCH, SWITCH FIELDSET IF USER IS NOT IN THE LAST FIELDSET
        if ($('body.registration').length) {
            $(document).on('keypress', function (e) {

                var x = event.which || event.keyCode

                if (x == 13) {

                    // On trigger le click sur next step uniquement si on n'est pas dans unt textarea !! (sinon, on ne peut pas revenir à la ligne dans le textarea)
                    if (!$('form.registration-form').find('*:focus').is('textarea') && !$('form.registration-form fieldset.shown').hasClass('item-4')) {
                        e.preventDefault();
                        $('.next-step a').trigger('click');
                    }
                }
            });
        }
    });

    function onHandyManLevelChange() {
        var index = $('#user_form_handymanLevel').prop('selectedIndex') - 1;
        $('#user_form_handymanLevel').parent().find('p').hide();

        if (index >= 0) {
            $('#user_form_handymanLevel').parent().find('p').eq(index).show();
        }
    }
})(jQuery);
