(function ($) {
    /* @ global vars */
    const smartWidthMax = 767;
    var currentWindowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    $(function () {
        $(window).on('resize', function (e) {
            currentWindowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        });

        /*
         -- @ LINKS @ --
         */
        $('a[href$=".pdf"], a.external-link').on('click', function (e) {
            e.preventDefault();
            window.open($(this).attr("href"));
        });
        $('a.backToTop').on('click', function (e) {
            e.preventDefault();
            $('body,html').animate({scrollTop: 0}, 250, 'swing');
        });
        $('a.noLink, a[href="GOTOLINK"]').on('click', function (e) {
            e.preventDefault();
        });


        /*
         -- @ MAIN MENU PHONE @ --
         */
        $('.open-menu button').on('click', function (e) {
            e.preventDefault();
            if ($(this).hasClass('is-active')) {
                $('html, body').removeClass('opened-menu');
                $('#main-header nav').removeClass('shown');
                $(this).removeClass('is-active');
            } else {
                $('html, body').addClass('opened-menu');
                $('#main-header nav').addClass('shown');
                $(this).addClass('is-active');
            }
        });
        $('.close-menu').on('click', function (e) {
            e.preventDefault();
            $('html, body').removeClass('opened-menu');
            $('#main-header nav').removeClass('shown');
            $('.open-menu button').removeClass('is-active');
        });


        if(document.querySelector('body > header .columns nav') !== null) {
            var hammermenu = new Hammer(document.querySelector('body > header .columns nav ul'));

            hammermenu.on('swipeleft', function (ev) {
                console.log(ev)
                $('html, body').removeClass('opened-menu');
                $('#main-header nav').removeClass('shown');
                $('.open-menu button').removeClass('is-active');
            });
        }


        /*
         -- @ LANG SWITCHER DROPDOWN @ --
         */
        var isLangHover = false;
        $('.flags').on('click', '> a', function (e) {
            e.preventDefault()
            $(this).next().toggleClass('shown')
        });
        // Manage langs display on mouse events
        $(document).on({
            mouseenter: function () {
                isLangHover = true
            },
            mouseleave: function () {
                isLangHover = false

                setTimeout(function () {
                    if (!isLangHover)
                        $('.flags > ul').removeClass('shown')
                }, 1000);
            }
        }, '.flags > ul')


        /*
        * HOMEPAGE : Scroll to #message if it exists
        * */
        if($('.homepage .home-presentation .alert').length > 0) {
            var _target = $('.homepage .home-presentation .alert');
            var _offset = 150;
            var _top = parseInt(_target.offset().top - _offset);
            $('html, body').stop(true, true).animate({scrollTop: _top}, 500, function () {
            });
        }


        /*
         -- @ BACKSTRETCH @ --
         * Attach responsive background-images to elements
         * Documentation : https://github.com/jquery-backstretch/jquery-backstretch
         */
        $('.backstretch').each(function (i, el) {
            var imgName = $(this).attr('data-img');

            if (imgName !== '' || imgName !== 'undefined')
                $(this).backstretch(imgName);
        });


        /*
            -- @ FLICKITY @ --
            * Make sliders cool cool
            * Documentation : https://flickity.metafizzy.co/
            * Used in Homepage, and all pages with items slider
        */
        if ($('.items-carousel').length > 0) {
            var $carousel = $('.items-carousel').flickity({
                contain: true,
                cellAlign: (currentWindowWidth > smartWidthMax) ? 'left' : 'center',
                imagesLoaded: true,
                prevNextButtons: (currentWindowWidth > smartWidthMax),
                pageDots: false,
                autoPlay: 5000, // Move each x miliseconds
                pauseAutoPlayOnHover: true,
                selectedAttraction: 0.08,
                friction: 0.7
            });

            // When we click on arrow, it make the slider in pause. So, launch player after clicking
            $carousel.on('select.flickity', function () {
                $carousel.flickity('playPlayer');
            });
            // When slider is loaded, hide loader and show slider
            $('.carousel .wrap-loader').fadeOut('fast', function () {
                $(this).remove();
                $carousel.addClass('shown');
            });
        }


        // SHOW BEST IDEAS OF PAST YEAR OR SHOW CURRENT IDEAS
        $(document).on('click', '[data-js="show-best-ideas"]', function(e){
            e.preventDefault();

            $('.last-ideas').hide();
            $('.best-ideas').show();

            // Init flickity
            var flkt = $('.best-ideas .best-items-carousel').flickity({
                contain: true,
                cellAlign: (currentWindowWidth > smartWidthMax) ? 'left' : 'center',
                imagesLoaded: true,
                prevNextButtons: (currentWindowWidth > smartWidthMax),
                pageDots: false,
                autoPlay: 5000,
                pauseAutoPlayOnHover: true,
                selectedAttraction: 0.08,
                friction: 0.7
            });
            flkt.resize();

            var _target = $('.home-last-ideas');
            var _offset = 150;
            var _top = parseInt(_target.offset().top - _offset);
            $('html, body').stop(true, true).animate({scrollTop: _top}, 500, function () {});
        });
        $(document).on('click', '[data-js="show-last-ideas"]', function(e){
            e.preventDefault();
            $('.last-ideas').show();
            $('.best-ideas').hide();

            var _target = $('.home-last-ideas');
            var _offset = 150;
            var _top = parseInt(_target.offset().top - _offset);
            $('html, body').stop(true, true).animate({scrollTop: _top}, 500, function () {});
        })


        /* FLICKITY HOMEPAGE BIG SLIDER */
        if ($('.main-home-carousel-2').length > 0) {
            var $homeSlider2 = $('.main-home-carousel-2').flickity({
                prevNextButtons: true,
                pageDots: false,
                cellAlign: 'left',
                wrapAround: true,
                selectedAttraction: 0.15,
                friction: 1,
                imagesLoaded: true,
                autoPlay: 8000
            });

            $homeSlider2.prev().fadeOut('fast', function () {
                $(this).remove();
                // $homeSlider2.resize();
            });


        }


        /*
            -- @ FLICKITY @ --
            * Make sliders cool cool
            * Documentation : https://flickity.metafizzy.co/
            * Used in:
                - Idea detail page
                - Blog article page
        */
        if($('.idea-carousel').length > 0) {
            var $ideaCarousel = $('.idea-carousel').flickity({
                cellSelector : '.idea-carousel-cell',
                contain: true,
                wrapAround: false, // Infinite horizontal scroll ?
                imagesLoaded: true,
                prevNextButtons: false,
                draggable: ($('.idea-carousel-cell').length > 1),
                pageDots: ($('.idea-carousel-cell').length > 1),
                autoPlay: 5000,
                pauseAutoPlayOnHover: true,
                selectedAttraction: 0.08,
                friction: 0.7,
                adaptiveHeight: true
            });
            // When we click on arrow, it make the slider in pause. So, launch player after clicking
            // $ideaCarousel.on('select.flickity', function() {
            //     $ideaCarousel.flickity('playPlayer');
            // });
            // When slider is loaded, hide loader and show slider
            $('.carousel .wrap-loader').fadeOut('fast',function(){
                $(this).remove();
                $ideaCarousel.addClass('shown');
            });
        }


        /*
         -- @ JQUERY CIRCULAR PROGRESS @ --
         * Documentation : https://github.com/kottenator/jquery-circle-progress
         */
        var
            circular_size = 58,
            circular_thickness = 'auto';

        if ($('.user-profil').length) {
            circular_size = 150;
            circular_thickness = 30
        }
        if ($('.idea-detail').length) {
            circular_size = 135;
            circular_thickness = 10
        }

        $('.circle').circleProgress({
            size: circular_size,
            startAngle: -Math.PI / 2,
            thickness: circular_thickness
        }).on('circle-animation-progress', function (event, progress, stepValue) {
            $(this).find('strong').html(Math.round(stepValue * 100) + '<em>%</em>');
        });


        /*
         -- @ SUBMIT FORMS : Display loading button @ --
         */
        $(document).on('submit', 'form.js-load-on-submit', function (e) {
            var
                form = $(this),
                inputs = form.find('*[required]'),
                submitBtn = form.find('*[type="submit"]');


            // Display loading submit button
            submitBtn.addClass('cc-loading');

            inputs.each(function (i, el) {
                if ($.trim($(this).val()) === '') {
                    e.preventDefault();

                    // Hide loading state
                    submitBtn.removeClass('cc-loading');
                }
            });
        });


        /*
         -- @ STICKY ELEMENTS HC STICKY @ --
         * Make user nav sticky into account page
         * Documentation : https://github.com/somewebmedia/hc-sticky
         */
        var stickyElement = new hcSticky('.sticky-item', {
            stickTo: '.js-sticky-kit',
            top: $('#main-header').innerHeight(),
            responsive: {
                767: {
                    disable: true
                }
            }
        });

        // Profile menu sticky on mobile
        $('.sticky-account-menu').hcSticky({
            stickTo: '#content > .cc-inside',
            top: $('#main-header').innerHeight(),
            onStart: function(){
                $(this).addClass('sticky-attached')
            }
        });


        /*
         -- @ LISTING FILTERS @ --
         * On phone, show/hide filters on touch event
         */
        $(document).on('click', '.listing-filters h2 a', function (e) {
            e.preventDefault();

            var _this = $(this);
            _this.parent().next().toggleClass('shown');
        })


        // PAGINATION DES IDEES
        $(document).on('click', '.discover-ideas .pagination a', function (e) {
            e.preventDefault();

            var url = $(this).attr('href');
            var page = $.urlParam(url, "page");

            updateProjectList(page, function () {
                $.scrollTo($('.wrap-listing-filters'),250)
            });
        });


        // AJAX GET IDES ACCORDING FILTERS
        if ($('body').hasClass('discover-ideas')) {
            var loader = '<div class="wrap-loader"><div class="cc-loader"><div class="spinner"></div></div></div>'

            updateProjectList();

            $("#project-form-filter").on('change', function (e) {
                e.preventDefault();
                updateProjectList();
            });

            var xhrCall;

            function updateProjectList(page, cb) {
                if (page == undefined)
                    page = 1;

                var form = $("#project-form-filter");

                var params = form.serialize();

                if (page > 1) {
                    params = params + "&page=" + page;
                }

                var uri = "";
                if (params.length > 0) {
                    uri = document.location.href.split('?')[0] + "?" + params;
                } else {
                    uri = document.location.href.split('?')[0];
                }

                window.history.pushState("", document.title, uri);

                $('.listing-items').html(loader);

                if(xhrCall != undefined){
                    xhrCall.abort();
                }
                xhrCall = $.get(form.attr('action'), params, function (res) {
                    $('.project-counter').find('span').hide();

                    var className = "";
                    if (res.nbProjects == 0) {
                        className = "none";
                    } else if (res.nbProjects == 1) {
                        className = "one";
                    } else {
                        className = "multiple";
                    }

                    $('.project-counter > .' + className).show().find("strong").text(res.nbProjects);
                    $('.listing-items').html(res.html);

                    // Launch Circle after html changed
                    $('.circle').circleProgress({
                        size: circular_size || 58,
                        startAngle: -Math.PI / 2,
                        thickness: circular_thickness || 'auto'
                    }).on('circle-animation-progress', function (event, progress, stepValue) {
                        $(this).find('strong').html(Math.round(stepValue * 100) + '<em>%</em>');
                    });

                    if (cb !== undefined) {
                        cb();
                    }
                });
            }
        }


        /*
         -- @ SELECT2 @ --
         * Select2 jquery plugin
         * Documentation : https://select2.github.io/
         */
        $(".js-select2").select2({});
        // Destroy select2 on mobile
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            $(".js-select2").select2('destroy');
        }


        /*
         -- @ PROFILE-DETAIL @ --
         * Change tabs on profile detail page
         */
        $('.middle-profile a').on('click', function (e) {
            e.preventDefault();

            var
                _this = $(this),
                rel = _this.data('rel');

            // Si onglet pas encore actif
            if (!$('.bottom-profile .' + rel).hasClass('active')) {

                // Tab link active class
                $('.middle-profile li').removeClass('active');
                _this.parent().addClass('active');

                // Show tab content
                $('.bottom-profile').find('.tab').removeClass('active');
                $('.bottom-profile .' + rel).addClass('active');

                if ($('.bottom-profile .' + rel).find('.items-carousel').length) {
                    $carousel.flickity('resize')
                }
            }
        });


        /*
            * SHOW/HIDE PASSWORD by clicking on eye icon
        */
        $(document).on('click', 'a.js-show-pass', function (e) {
            e.preventDefault();

            var
                _this = $(this),
                _input = _this.parent().find('input'),
                _eye = 'fa fa-eye',
                _eye_disabled = 'fa fa-eye-slash';

            if (_input.attr('type') === 'text') {
                _input.attr('type', 'password');
                _this.find('i').attr('class', _eye);
            } else {
                _input.attr('type', 'text');
                _this.find('i').attr('class', _eye_disabled);
            }
        });


        /*
        * FAQ : Dropdown
        * */
        $(document).on('click','[data-expandonclick]',function(e) {
            e.preventDefault();

            var _this = $(this);
            var _target = $(_this.data('expandonclick'));

            _target.stop(true,true).slideToggle('fast');
            _this.toggleClass('rotate-icon');
        });

        /*
        * FAQ CATEGORIES
        * */
        $(document).on('click','[data-scrollto]',function(e) {
            e.preventDefault();

            var _el = $(this).data('scrollto');
            var _target = $(_el);
            var _offset = 150;
            var _top = parseInt(_target.offset().top - _offset);
            $('html, body').stop(true, true).animate({scrollTop: _top}, 500, function () {});
        });

    });

    $.urlParam = function (url, name) {
        var results = new RegExp('[\?&]' + name + '=([^&#]*)')
            .exec(url);

        return results[1] || 0;
    }

})(jQuery);
