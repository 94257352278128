// JS SPECIFIC TO IDEA DETAIL PAGE
(function($) {
    $(function () {

        // Switch tabs on idea detail page
        $('.idea-detail .main-tabs li:not(.last) a').on('click',function(e) {
            e.preventDefault();

            var
                _this = $(this),
                rel = _this.data('rel'),
                activeTab = _this.html();

            // Si onglet pas encore actif
            if(!$('#'+rel).hasClass('active')) {

                // Tab link active class
                $('.idea-detail .main-tabs li').removeClass('active');
                _this.parent().addClass('active');


                // Show tab content
                $('.wrap-idea-author').find('.tab').removeClass('active');
                $('#'+rel).addClass('active');


                // Phone !!
                $('.idea-menu').find('span').html(activeTab);
                $('.idea-menu i.fa-bars').show();
                $('.idea-menu i.fa-times').hide();
                $('.main-tabs').removeClass('shown');
            }
        });


        // Tabs on phone
        $(document).on('click','.idea-menu', function(e){
            e.preventDefault();

            if($('.main-tabs').is(':visible')) {
                $('.main-tabs').removeClass('shown');
                $('.idea-menu i.fa-bars').show();
                $('.idea-menu i.fa-times').hide();
            } else {
                $('.main-tabs').addClass('shown');
                $('.idea-menu i.fa-times').show();
                $('.idea-menu i.fa-bars').hide();
            }
        });


        // HASH on Tabs
        if($('.main-tabs').length && window.location.hash) {
            var
                hashes = ['description','updates','comments','supporters'],
                url = window.location.hash,
                finalHash = url.substring(1);



            // Si le hash correspond à un hash valide
            if (hashes.indexOf(finalHash) > -1) {
                var
                    tabLink = $('.main-tabs').find('a[href="#'+finalHash+'"]'),
                    tabLinkRel = tabLink.data('rel');

                $('.main-tabs').find('li').removeClass('active')
                $('.wrap-idea-author').find('.tab').removeClass('active')

                // Highlight tab and content
                tabLink.parent().addClass('active')
                $('#'+tabLinkRel).addClass('active')

            }
            else {
                console.log('hash "' + finalHash + '" is not an valid hash. Available hashes are "description", "updates", "comments" and "supporters');
            }
        }


        // Profile menu sticky on mobile
        $('.wrap-idea-menu').hcSticky({
            stickTo: '#content > .main > .cc-inside',
            top: $('#main-header').innerHeight(),
            onStart: function(){
                $(this).addClass('sticky-attached')
            }
        });
    });
})(jQuery);
