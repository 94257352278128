// JS SPECIFIC TO COMMENTS FORMS
(function($) {



    $(function () {

        /*
            -- @ REPLY TO A COMMENT @ --
        */
        var form_reply = $('.new-comment');

        $(document).on('click','.js-comment-reply', function(e){
            e.preventDefault();

            var comment = $(this).parents('.comment-item');
            var comment_id = comment.data('id');

            // the variable is defined ?
            if (typeof comment_id !== 'undefined') {

                // Change hidden field
                $('input[id="project_comment_form_commentToReplyId"]').val(comment_id);


                // Scroll to the comment form
                $.scrollTo(form_reply,350,{offset:-120, axis:'y'});

            }
        })
        // $(document).on('click','.js-cancel-reply', function(e){
        //     e.preventDefault();

        //     // Move the form at the bottom of the page
        //     form_reply
        //         .insertAfter($('.comments'))
        //         .removeClass('moved')
        //         .find('.cancel-reply').hide();


        //     // Clear hidden field
        //     $('input[id="project_comment_form_commentToReplyId"]').removeAttr('value');
        // })


    });
})(jQuery);
